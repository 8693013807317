import React from 'react';
import { Route } from 'react-router-dom';
import Lazy from '../containers/Lazy';
import ContentWrapper from '../components/ContentWrapper';

const wrapRoute = ({ label, component, wrap }) => props =>
  wrap ? (
    <ContentWrapper title={label}>
      <Lazy {...{ ...props, component }} />
    </ContentWrapper>
  ) : (
    <Lazy {...{ ...props, component }} />
  );

export const renderRoutes = routes =>
  routes.map(route => (
    <Route
      {...{
        key: route.label,
        ...route,
        ...{ component: wrapRoute(route) },
      }}
    />
  ));
