import styled from 'styled-components';
import { transparentize } from 'polished';
import { COLORS } from '../../../../constants/colors';
import { OneLogo } from '../../OneLogo';

export const Template = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 8px 0;
  background-color: ${transparentize(0.6, COLORS.BLACK)};

  ${OneLogo} {
    margin-right: auto;
  }
`;

Template.displayName = 'Template';
