import styled from 'styled-components';
import { Title } from './Title';
import { Description } from './Description';
import { Icon } from './Icon';

export const Template = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 30px;
`;

Template.displayName = 'Template';
Template.Title = Title;
Template.Description = Description;
Template.Icon = Icon;
