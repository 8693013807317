import React from 'react';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { DrizzleProvider } from '@drizzle/react-plugin';
import { LoadingContainer } from '@drizzle/react-components';
import firebase from 'firebase/app';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import { GlobalStyle, snackbarStyles } from '../../config/globalStyle';
import { configureStore, drizzleOptions } from '../../modules/store/';
import configureFirebase from '../../modules/firebase/configureFirebase';
import theme from '../../styles/mui-theme';
import Routes from '../../routes';
import Notifier from '../Notifier';
import Lazy from '../Lazy';
import Blockchain from '../Blockchain';
import { LandingPage } from '../../components/LandingPage';
import { Loader } from '../../components/Loader';

const App = ({ classes }) => {
  /**
   * This is a simple hack to load the Landing page before requiring
   * Metamask data. Unfortunately we are not able to use the routes
   * file due to context requirements which break the code.
   */
  const LANDING_ROUTES = ['/', '/home'];
  const shouldRenderLanding = LANDING_ROUTES.includes(window.location.pathname);
  if (shouldRenderLanding) {
    return (
      <>
        <GlobalStyle />
        <LandingPage />
      </>
    );
  }

  configureFirebase();
  const drizzleStore = configureStore();
  const rrfProps = {
    firebase,
    config: { enableLogging: process.env.NODE_ENV === 'development' },
    dispatch: drizzleStore.dispatch,
  };

  return (
    <DrizzleProvider options={drizzleOptions} store={drizzleStore}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={4} classes={classes}>
            <LoadingContainer loadingComp={<Loader />}>
              <Blockchain>
                <Provider store={drizzleStore}>
                  <>
                    <GlobalStyle />
                    <Notifier />
                    <Lazy component={Routes} />
                  </>
                </Provider>
              </Blockchain>
            </LoadingContainer>
          </SnackbarProvider>
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </DrizzleProvider>
  );
};

export default withStyles(snackbarStyles)(App);
