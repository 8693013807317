import React from 'react';
import { HeaderWrapper } from './HeaderWrapper';
import { HeaderInnerInfo } from './HeaderInnerInfo';
import { ProductName } from './ProductName';
import { PendingIcon } from '../PendingList';
import { Network } from '../Network';
import { Typography } from '@material-ui/core';

const Header = () => (
  <HeaderWrapper>
    <ProductName>Oken - Digital Assets Platform</ProductName><Typography variant="overline">(Beta)</Typography>
    <HeaderInnerInfo>
      <Network />
      <PendingIcon />
    </HeaderInnerInfo>
  </HeaderWrapper>
);

export default Header;
