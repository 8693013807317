import React from 'react';
import { Template } from './Template';

export const MiniInfo = ({ icon: Icon, title, description }) => (
  <Template>
    <Template.Icon>
      <Icon />
    </Template.Icon>
    <Template.Title>{title}</Template.Title>
    <Template.Description>{description}</Template.Description>
  </Template>
);
