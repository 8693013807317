import React from 'react';
import FooterWrapper from './Wrapper';
import { Grid } from '../../Grid';

export const Footer = () => {
  return (
    <FooterWrapper>
      <Grid as="p">
        &copy; @{new Date().getFullYear()} OnePercent. All rights reserved.
      </Grid>
    </FooterWrapper>
  );
};
