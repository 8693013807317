import includes from 'lodash/includes';
import { notifierEnqueue } from '../../modules/store/reducers/notifier';

export const lifecycleFormMiddleware = api => next => action => {
  switch (action.type) {
    case 'EVENT_FIRED':
      const { event } = action.event;
      if (includes(['Paused', 'Unpaused'], event)) {
        api.dispatch(
          notifierEnqueue({
            message: `Token successfully ${event.toLowerCase()}`,
            options: { variant: 'success' },
            txHash: action.event.transactionHash,
          })
        );
      }
      next(action);
      break;
    default:
      next(action);
  }
};
