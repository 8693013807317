export const MAX_WIDTHS = Object.freeze({
  XS: 340,
  SM: 540,
  MD: 720,
  LG: 960,
  XL: 1168,
});

export const BREAKPOINTS = Object.freeze({
  XS: 320,
  SM: 576,
  MD: 758,
  LG: 992,
  XL: 1200,
});
