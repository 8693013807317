const { setFetchingTokens } = require('../../../containers/Blockchain/Blockchain.redux').tokens.actions
const { notifierEnqueue } = require('../../../modules/store/reducers/notifier').actions

/**
 * PUSH_TO_TXSTACK 
 * SEND_CONTRACT_TX { args, contract, fnName }
 * TX_BROADCASTED { txHash }
 * CONTRACT_SYNC_IND
 * BLOCK_RECEIVED
 * EVENT_FIRED
 * TX_SUCCESSFUL { txHash, receipt }
 */

export const tokenFormMiddleware = api => next => action => {
    switch (action.type) {
        case 'EVENT_FIRED':
            const { event, returnValues } = action.event
            if (event === 'TokenCreated') {
                const { name, symbol } = returnValues
                api.dispatch(setFetchingTokens())
                api.dispatch(notifierEnqueue({
                    message: `Token ${symbol} - ${name} created. You can check it in the token list.`,
                    options: { variant: 'success' },
                    txHash: action.event.transactionHash
                }))
            }
            next(action)
            break
        default:
            next(action)
        }
}