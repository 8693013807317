import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ROUTES, OPEN_ROUTES, PATHS } from './constants';
import LoggedTemplate from '../components/LoggedTemplate';
import { renderRoutes } from './helper';

const RouterConfig = ({ auth }) => (
  <BrowserRouter>
    {auth.uid ? (
      <LoggedTemplate>
        <Switch>
          {renderRoutes(Object.values(ROUTES))}
          <Redirect from="*" to={PATHS.DASHBOARD} />
        </Switch>
      </LoggedTemplate>
    ) : (
      <Switch>
        {renderRoutes(Object.values(OPEN_ROUTES))}
        <Redirect from="*" to={PATHS.LOGIN} />
      </Switch>
    )}
  </BrowserRouter>
);

export default compose(
  connect(({ firebase: { auth } }) => ({
    auth,
  }))
)(RouterConfig);
