import autodux from 'autodux'
import _ from 'lodash'

/**
 * PUSH_TO_TXSTACK 
 * SEND_CONTRACT_TX { args, contract, fnName }
 * TX_BROADCASTED { txHash, stackId }
 * CONTRACT_SYNC_IND
 * BLOCK_RECEIVED
 * EVENT_FIRED
 * TX_SUCCESSFUL { txHash, receipt }
 */

export const pendingList = autodux({
    slice: 'pendingList',
    initial: {
        tx: {},
        list: [],
        fetching: true,
        settingChannel: false,
        channelSet: false
    },
    actions: {
        savePendingTx: (state, payload) => ({...state, tx: payload }),
        setPendingList: (state, payload) => ({...state, list: payload, fetching: false }),
        refreshPendingTxs: state => state,
        setChannel: state => ({ ...state, settingChannel: true, channelSet: false }),
        channelSet: state => ({ ...state, settingChannel: false, channelSet: true }),
    }
})

export const pendingListMiddleware = api => next => action => {
    switch (action.type) {
        case 'SEND_CONTRACT_TX':
            api.dispatch(pendingList.actions.savePendingTx({
                ..._.omit(action, 'contract'),
                contractName: action.contract.contractName,
                type: 'TX_CREATED'
            }))
            return next(action)
        case 'TX_BROADCASTED':
            api.dispatch(pendingList.actions.savePendingTx(action))
            return next(action)
        case 'TX_SUCCESSFUL':
            api.dispatch(pendingList.actions.savePendingTx(action))
            return next(action)
        case 'TX_ERROR':
            api.dispatch(pendingList.actions.savePendingTx(action))
            return next(action)
        default:
            next(action)
        }
}