import styled from 'styled-components';
import { transparentize } from 'polished';
import { Icon } from './Icon';
import { COLORS } from '../../../constants/colors';

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  position: fixed;
  left: 0;
  top: 0;

  width: 55px;
  height: 100vh;
  background-color: ${COLORS.WHITE};
  z-index: 2;
  box-shadow: 0 0 3px ${transparentize(0.8, COLORS.BLACK)};
`;

Nav.displayName = 'Nav';
Nav.Icon = Icon;
