import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import { COLORS } from '../../../constants/colors';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  width: 38px;
  height: 38px;
  border-radius: 8px;
  color: ${COLORS.DARK_GRAY};
  ${transitions(['background-color', 'color'], '.3s')}

  svg {
    font-size: 22px;
  }

  &:hover {
    background-color: ${COLORS.GRAY};
    color: ${COLORS.BLACK};
  }

  ${({ active }) => active && ACTIVE}
  ${({ logo }) => logo && LOGO}
  ${({ alignBottom }) => alignBottom && ALIGN_BOTTOM}
`;

const ACTIVE = css`
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.WHITE};
  box-shadow: 0px 7px 9px -5px ${COLORS.PRIMARY};

  &:hover {
    background-color: ${COLORS.SECONDARY};
    color: ${COLORS.WHITE};
  }
`;

const LOGO = css`
  margin-top: 10px;
  margin-bottom: 60px;
`;

const ALIGN_BOTTOM = css`
  margin-top: auto;
`;

Icon.displayName = 'Icon';
