import React from 'react';
import { LandingTemplate } from '../LandingTemplate';
import { Grid } from '../../Grid';
import { MiniInfo } from '../MiniInfo';
import { DETAILS } from './constants';
import { COLORS } from '../../../constants/colors';

export const Details = () => (
  <LandingTemplate backgroundColor={COLORS.WHITE}>
    <Grid>
      <Grid.Row>
        {DETAILS.map(({ title, description, icon }) => (
          <Grid.Col size={4} key={title}>
            <MiniInfo {...{ title, description, icon }} />
          </Grid.Col>
        ))}
      </Grid.Row>
    </Grid>
  </LandingTemplate>
);
