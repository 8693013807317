import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import { put, call,  takeEvery, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'
import moment from 'moment'
import abiDecoder from 'abi-decoder'
import tokenFactoryAddresses from '../../contracts/tokenFactoryAddresses.json'

import ControlledToken from '../../contracts/ControlledToken.json'
import TokenAccessList from '../../contracts/TokenAccessList.json'
import TokenFactory from '../../contracts/TokenFactory.json'
import { tokens, accessLists, drizzle, account, txHistory, tokenFactory } from './Blockchain.redux'
import { ADMIN_EVENTS } from '../../utils/events'

const {
    actions: {
        setFetchingTokens,
        setTokensFetched,
        setTokensInitialized
    }
} = tokens

const {
    actions: {
        setFetchingAccessLists,
        setAccessListsFetched,
        setAccessListsInitialized,
    }
} = accessLists

const {
    actions: {
        setFetchingTxHistory,
        setTxHistoryFetched,
        setHistoryCacheHit
    }
} = txHistory

const {
    actions: {
        setInitializingTokenFactory,
        setInitializedTokenFactory
    }
} = tokenFactory

const { selectors: { getDrizzle }} = drizzle
const { selectors: { getConnectedAccount }} = account

abiDecoder.addABI(TokenFactory.abi)
abiDecoder.addABI(TokenAccessList.abi)
abiDecoder.addABI(ControlledToken.abi)

let lastTxCheck

// TODO: get token created events from token factory deployment block
const API = {
    fetchTokenCreationEvents: async (factory, owner) => await factory.getPastEvents('TokenCreated', { fromBlock: 0, filter: { creator: owner } }),
    fetchAccessListCreationEvents: async (factory, owner) => await factory.getPastEvents('AccessListCreated', { fromBlock: 0, filter: { creator: owner } }),
    fetchTokenSupply: async contract => await contract.methods.totalSupply().call(),
    fetchTxList: async (address, fromBlock, toBlock, networkId) =>
        firebase.functions().httpsCallable('getTxList')({ address, fromBlock, toBlock, networkId }),
}

const cachedFindContract = list => {
    const cache = {}
    return address => {
        if (!cache[address]) cache[address] = _.find(list, ({ address: listAddress }) => listAddress.toLowerCase() === address )
        return cache[address]
    }
}

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

function *_checkIfTokenFactoryIsInitialized() {
    while (true) {
        const { tokenFactory } = yield select()
        if (tokenFactory.initialized) break;
        yield delay(200)
    }
}

function *initializeTokenFactory() {
    const { web3: { networkId } } = yield select()
    const { instance } = yield select(getDrizzle)
    const{ web3 } = instance

    const tokenFactoryAddress = tokenFactoryAddresses[networkId]

    yield instance.addContract({
        contractName: 'TokenFactory',
        web3Contract: new web3.eth.Contract(TokenFactory.abi, tokenFactoryAddress)
    }, ['TokenCreated', 'AccessListCreated'])

    yield put(setInitializedTokenFactory())
}

function *_checkIfTokensAreInitialized() {
    let networkId, tokens
    while (true) {
        ({ web3: { networkId }, tokens } = yield select())
        if (tokens.initialized) break;
        yield delay(200)
    }
    return networkId
}

function *fetchTxHistory() {
    if (lastTxCheck && moment().diff(lastTxCheck, 'seconds') < 10) {
        return yield put(setHistoryCacheHit())
    }
    lastTxCheck = moment()

    const { instance } = yield select(getDrizzle)
    const networkId = yield _checkIfTokensAreInitialized()

    const { web3, contractList } = instance
    const latestBlock = yield web3.eth.getBlockNumber()
    const account = yield select(getConnectedAccount)
    const { data: txs } = yield call(API.fetchTxList, account, 0, latestBlock, networkId)
    // create decoders
    const findContract = cachedFindContract(contractList)
    const decoded = _(txs)
        .filter(tx => findContract(tx.to))
        .map(tx => {
            return {
                ...tx,
                decodedInput: abiDecoder.decodeMethod(tx.input),
                contractName: findContract(tx.to).contractName
            }
        })
        .value()
    yield put(setTxHistoryFetched(decoded))
}

// fetch data from service using sagas
function *fetchTokens() {
    yield _checkIfTokenFactoryIsInitialized()
    const { instance } = yield select(getDrizzle)
    const connectedAccount = yield select(getConnectedAccount)
    const { web3, contracts: { TokenFactory } } = instance
    
    const factory = new web3.eth.Contract(TokenFactory.abi, TokenFactory.address)
    const events = yield call(API.fetchTokenCreationEvents, factory, connectedAccount)
    const tokens = []
    for (const event of events) {
        const { name, symbol, decimals, token, blockNumber, creator } = event.returnValues
        const contract = new web3.eth.Contract(ControlledToken.abi, token, { from: creator })
        const totalSupply = yield call(API.fetchTokenSupply, contract)
        tokens.push({
            web3Contract: Object.assign(contract, { blockNumber }),
            name,
            symbol,
            decimals,
            totalSupply,
            address: token,
            blockNumber,
            type: 'token',
        })
    }
    // store token info
    yield put(setTokensFetched(tokens))
    // dinamycally add tokens to drizzle
    const eventsToWatch = ADMIN_EVENTS
    for (const token of tokens) {
        const { address: contractName, web3Contract } = token
        if (!instance.contracts[contractName]) yield instance.addContract({ contractName, web3Contract }, eventsToWatch)
    }
    yield put(setTokensInitialized())
}

function *fetchAccessLists() {
    yield _checkIfTokenFactoryIsInitialized()
    const { instance } = yield select(getDrizzle)
    const connectedAccount = yield select(getConnectedAccount)
    const { web3, contracts: { TokenFactory } } = instance
    const factory = new web3.eth.Contract(TokenFactory.abi, TokenFactory.address)
    const events = yield call(API.fetchAccessListCreationEvents, factory, connectedAccount)
    const lists = []
    for (const event of events) {
        const { accessList, blockNumber, creator, identifier } = event.returnValues
        const contract = new web3.eth.Contract(TokenAccessList.abi, accessList, { from: creator })
        lists.push({
            web3Contract: Object.assign(contract, { blockNumber }),
            address: accessList,
            identifier,
            blockNumber,
            type: 'accessList',
        })
    }
    // store accessList info
    yield put(setAccessListsFetched(lists))
    // if there's no accessList, just return
    if (_.isEmpty(lists)) return
    
    // dinamycally add accessLists to drizzle
    const eventsToWatch = [ 'WalletEnabled', 'WalletDisabled' ]
    // we'll use only the last accessList
    for (const accessList of lists) {
        const { address: contractName, web3Contract } = accessList
        if (!instance.contracts[contractName]) yield instance.addContract({ contractName, web3Contract }, eventsToWatch)
    }
    yield put(setAccessListsInitialized())
}

// Combine all your redux concerns

// app root saga
function *fetchTokensSaga() {
    yield takeLatest(setFetchingTokens.type, fetchTokens)
}

function *fetchTxHistorySaga() {
    yield takeLatest(setFetchingTxHistory.type, fetchTxHistory)
}

function *fetchAccessListsSaga() {
    yield takeEvery(setFetchingAccessLists.type, fetchAccessLists)
}

function *initializeTokenFactorySaga() {
    yield takeLatest(setInitializingTokenFactory.type, initializeTokenFactory)
}

export const blockchainSagas = [fetchTokensSaga, fetchAccessListsSaga, fetchTxHistorySaga, initializeTokenFactorySaga]