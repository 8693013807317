import React from 'react';
import { LandingTemplate } from '../LandingTemplate';
import { Grid } from '../../Grid';
import { Button } from '../../Button';
import { COLORS } from '../../../constants/colors';
import background from '../../../assets/img/header.jpg';

export const Header = () => (
  <LandingTemplate
    backgroundImage={background}
    backgroundColor={COLORS.DARKEST_GRAY}
    color={COLORS.WHITE}
    spaced
  >
    <Grid>
      <LandingTemplate.Title large>Welcome to Oken Platform</LandingTemplate.Title>
      <LandingTemplate.Description spaced>
        Your platform for digital asset management, based on Ethereum and Rsk.co technology. Explore the future.
      </LandingTemplate.Description>
      <Button large as="a" href="mailto:business@onepercent.io">
        Contact us now
      </Button>
    </Grid>
  </LandingTemplate>
);
