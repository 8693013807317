const reducer = 'notifier';

/** SELECTORS */
const getNotifications = state => state[reducer].notifications;

export const selectors = {
  getNotifications,
};

/** TYPES */
const ENQUEUE = `${reducer}/ENQUEUE`;
const DISMISS = `${reducer}/DISMISS`;

export const types = {
  ENQUEUE,
  DISMISS,
};

const defaultConfig = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
    autoHideDuration: 2000,
  },
};

/** ACTIONS */
export const notifierEnqueue = notification => ({
  type: types.ENQUEUE,
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification,
    options: {
      ...defaultConfig,
      ...notification.options,
    },
  },
});

const notifierDismiss = key => ({
  type: types.DISMISS,
  key,
});

// TODO: see need for this export
export const actions = {
  notifierEnqueue,
  notifierDismiss,
};

/** REDUCERS */
const defaultState = {
  notifications: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.ENQUEUE:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case types.DISMISS:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};
