/**
 * Trims the end of a given string and adds ellipsis based on the `characterLimit` if necessary.
 *
 * @param {string} entry - The entry text to be manipulated
 * @param {number} characterLimit - The maximum number of characters to validate and add ellipsis if needed
 *
 * @return {string} - Formatted string in the shape of `Long tex...`
 */
export const trimWithEllipsis = (entry = '', characterLimit = 25) => {
  const shouldTrimWithEllipsis = entry.length > characterLimit;
  return shouldTrimWithEllipsis
    ? `${entry.substring(0, characterLimit).trim()}...`
    : entry;
};
