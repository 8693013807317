import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 30px;

  ${({ center }) => center && CENTER};
`;

const CENTER = css`
  justify-content: center;
`;

Container.displayName = 'Container';
