import { lazy } from 'react';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';


const Login = lazy(() => import('../containers/Login'));
const TokenList = lazy(() => import('./pages/TokenList'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const CreateToken = lazy(() => import('./pages/TokenForm'));
const AccessLists = lazy(() => import('./pages/AccessLists'));
const AccessListForm = lazy(() => import('./pages/AccessListForm'));
const AccessListControl = lazy(() => import('./pages/AccessList'));
const PendingList = lazy(() => import('./pages/PendingList'));

// Nested routes concept
const TokenRoutes = lazy(() => import('./Token'));

export const PATHS = Object.freeze({
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  PAYOUTS: '/payout/:token',
  PENDING_LIST: '/pending-list',
  TOKENS: '/tokens',
  CREATE_TOKEN: '/tokens/create-token',
  TOKEN: '/tokens/:address',
  ACCESS_LISTS: '/access-lists',
  CREATE_ACCESS_LIST: '/access-lists/create-access-list',
  ACCESS_LIST_CONTROL: '/access-lists/:address',
  FORCED_TRANSFER: '/forced-transfer'
});

export const OPEN_ROUTES = Object.freeze({
  LOGIN: {
    component: Login,
    path: PATHS.LOGIN,
    label: 'Login',
  },
});

export const ROUTES = Object.freeze({
  DASHBOARD: {
    component: Dashboard,
    icon: DashboardRoundedIcon,
    path: PATHS.DASHBOARD,
    label: 'Dashboard',
    wrap: true,
  },
  TOKENS: {
    component: TokenList,
    icon: MonetizationOnRoundedIcon,
    path: PATHS.TOKENS,
    label: 'Token List',
    exact: true,
  },
  CREATE_TOKEN: {
    component: CreateToken,
    path: PATHS.CREATE_TOKEN,
    label: 'New Token',
    exact: true,
    wrap: true,
  },
  PENDING_LIST: {
    component: PendingList,
    icon: HourglassEmptyRoundedIcon,
    path: PATHS.PENDING_LIST,
    label: 'Pending List',
    wrap: true,
  },
  TOKEN: {
    component: TokenRoutes,
    path: PATHS.TOKEN,
    label: 'Token Control',
  },
  ACCESS_LISTS: {
    component: AccessLists,
    icon: PlaylistAddCheckRoundedIcon,
    path: PATHS.ACCESS_LISTS,
    label: 'Access Lists',
    exact: true,
  },
  CREATE_ACCESS_LIST: {
    component: AccessListForm,
    path: PATHS.CREATE_ACCESS_LIST,
    label: 'New Access List',
    exact: true,
    wrap: true,
  },
  ACCESS_LIST_CONTROL: {
    component: AccessListControl,
    path: PATHS.ACCESS_LIST_CONTROL,
    label: 'Access List',
  },
});

export const MENU_ROUTES = [
  ROUTES.DASHBOARD,
  ROUTES.TOKENS,
  ROUTES.ACCESS_LISTS,
  ROUTES.PENDING_LIST,
];
