import React, { memo } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { Nav } from './Nav';
import { MENU_ROUTES } from '../../routes/constants';
import logo from '../../assets/img/logo.png';

const getLocationBasePath = location => `/${location.pathname.split('/')[1]}`;

const Navbar = ({ location }) => (
  <Nav>
    <Nav.Icon as="img" src={logo} alt="OnePercent" logo />
    {MENU_ROUTES.map(({ label, path: to, icon: Icon }) => (
      <Link {...{ key: label, to }}>
        <Tooltip title={label} placement="right">
          <Nav.Icon active={to === getLocationBasePath(location)}>
            <Icon />
          </Nav.Icon>
        </Tooltip>
      </Link>
    ))}
    <Nav.Icon as="button" alignBottom onClick={() => firebase.auth().signOut()}>
      <ExitToAppRoundedIcon />
    </Nav.Icon>
  </Nav>
);

Navbar.propTypes = {
  location: PropTypes.object,
};

export default memo(withRouter(Navbar));
