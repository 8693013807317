import React from 'react';
import { LandingTemplate } from '../LandingTemplate';
import { Grid } from '../../Grid';

export const GenericBlock = ({
  title,
  description,
  alignRight,
  size,
  image,
  // Wrapper
  backgroundImage,
  backgroundColor,
  color,
  // Row
  center,
  reverse,
  roundSeparator,
}) => (
  <LandingTemplate
    {...{ backgroundImage, backgroundColor, color, roundSeparator }}
  >
    <Grid>
      <Grid.Row {...{ center, reverse }}>
        {image && (
          <Grid.Col size={6}>
            <LandingTemplate.Image {...image} />
          </Grid.Col>
        )}
        <Grid.Col {...{ alignRight, size }}>
          <LandingTemplate.Title>{title}</LandingTemplate.Title>
          <LandingTemplate.Description>
            {description}
          </LandingTemplate.Description>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </LandingTemplate>
);
