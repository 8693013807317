import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Title } from './Title';
import { Description } from './Description';
import { Image } from './Image';
import { COLORS, GRADIENTS } from '../../../constants/colors';
import { MEDIA_MAX } from '../../../utils/mediaQuery';
import roundEdge from '../../../assets/img/round-edge.png';

export const LandingTemplate = styled.div`
  position: relative;
  padding: 150px 0;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.GRAY};
  color: ${({ color }) => color || COLORS.BLACK};

  ${MEDIA_MAX.MD`
    text-align: center;
    padding: 80px 0;
  `}

  ${({ backgroundImage }) => backgroundImage && BACKGROUND(backgroundImage)};
  ${({ spaced }) => spaced && SPACED};
  ${({ center }) => center && CENTER};
  ${({ colorful }) => colorful && COLORFUL};
  ${({ roundSeparator }) => roundSeparator && ROUND_SEPARATOR};
`;

const BACKGROUND = backgroundImage => css`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  text-shadow: 0 0 3px ${transparentize(0.2, COLORS.BLACK)};
`;

const SPACED = css`
  padding: 300px 0;

  ${MEDIA_MAX.MD`
    padding: 225px 0 170px;
  `}
`;

const CENTER = css`
  text-align: center;
`;

const COLORFUL = css`
  ${GRADIENTS.PRIMARY_TO_SECONDARY_RIGHT}
`;

const ROUND_SEPARATOR = css`
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 35px;
    background: url(${roundEdge}) no-repeat;
    background-size: cover;
    background-position: bottom;
    position: absolute;
    left: 0;
  }

  &:before {
    top: 0;
    transform: rotate(180deg);
  }

  &:after {
    bottom: 0;
  }
`;

LandingTemplate.displayName = 'LandingTemplate';
LandingTemplate.Title = Title;
LandingTemplate.Description = Description;
LandingTemplate.Image = Image;
