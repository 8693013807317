import LockRoundedIcon from '@material-ui/icons/LockRounded';
import ShutterSpeedRoundedIcon from '@material-ui/icons/ShutterSpeedRounded';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import DonutSmallRoundedIcon from '@material-ui/icons/DonutSmallRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import LeakAddRoundedIcon from '@material-ui/icons/LeakAddRounded';

export const DETAILS = Object.freeze([
  {
    title: 'Safe',
    description: 'Assets protected by Blockchain Cryptography.',
    icon: LockRoundedIcon,
  },
  {
    title: 'Fast',
    description: 'Build on top of fast and reliable technologies.',
    icon: ShutterSpeedRoundedIcon,
  },
  {
    title: 'Business Centric',
    description:
      'We offer tools for customer management and asset lifecycle.',
    icon: LocalAtmRoundedIcon,
  },
  {
    title: 'Chart Visualizations',
    description:
      'Simple, easy to use and user friendly.',
    icon: DonutSmallRoundedIcon,
  },
  {
    title: 'Categories',
    description:
      'Authorizations, assets, issuance and transactions.',
    icon: CategoryRoundedIcon,
  },
  {
    title: 'Secure Communications',
    description:
      'Only authorized users have access to your assets.',
    icon: LeakAddRoundedIcon,
  },
]);
