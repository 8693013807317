import styled, { css } from 'styled-components';
import { MEDIA_MAX } from '../../../utils/mediaQuery';

export const Description = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: normal;
  max-width: 460px;

  ${MEDIA_MAX.MD`
    font-size: 16px;
    margin: 0 auto 10px;
  `}
  
  ${({ center }) => center && CENTER}
  ${({ spaced }) => spaced && SPACED}
`;

const SPACED = css`
  margin-bottom: 30px;
  ${MEDIA_MAX.MD`
    margin-bottom: 30px;
  `}
`;

const CENTER = css`
  margin: 0 auto 10px;
`;

Description.displayName = 'Description';
