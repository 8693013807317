import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

const FooterWrapper = styled.footer`
  position: relative;
  padding: 15px 0;
  background-color: ${COLORS.DARKEST_GRAY};
  color: ${COLORS.WHITE};
  text-align: center;
`;

FooterWrapper.displayName = 'FooterWrapper';

export default FooterWrapper;
