import styled from 'styled-components';
import logo from '../../../assets/img/logo-white.png';

export const OneLogo = styled.img.attrs({
  src: logo,
  alt: 'One Percent Logo',
})`
  width: 30px;
  height: 30px;
`;

OneLogo.displayName = 'OneLogo';
