import styled from 'styled-components';
import { MainContent } from './MainContent';

export const Template = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  width: 100vw;
`;

Template.displayName = 'Template';
Template.MainContent = MainContent;
