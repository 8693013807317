import React from 'react';
import PropTypes from 'prop-types';
import { Template } from './Template';
import Header from '../Header';
import Navbar from '../Navbar';

const LoggedTemplate = ({ children }) => (
  <Template>
    <Navbar />
    <Header />
    <Template.MainContent>{children}</Template.MainContent>
  </Template>
);

LoggedTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedTemplate;
