import styled, { css } from 'styled-components';
import { MEDIA_MAX } from '../../utils/mediaQuery';

const AUTO = css`
  flex: 1;
`;

const FIXED_SIZE = size => {
  const columnSize = (size / 12) * 100;
  return css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(${columnSize}% - 30px);
  `;
};

export const Col = styled.div`
  margin: 0 15px;
  flex: 1;

  ${({ center }) => center && CENTER}

  ${MEDIA_MAX.MD`
    ${FIXED_SIZE(12)}
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${({ size }) => (size ? FIXED_SIZE(size) : AUTO)}
  ${({ alignRight }) => alignRight && ALIGN_RIGHT}
`;

const CENTER = css`
  text-align: center;
`;

const ALIGN_RIGHT = css`
  margin-left: auto;
`;

Col.displayName = 'Col';
