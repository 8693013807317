import styled from 'styled-components';
import { COLORS } from '../../../../constants/colors';

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 18px;
  width: 74px;
  height: 74px;
  border-radius: 22px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY};

  svg {
    font-size: 36px;
  }
`;

Icon.displayName = 'Icon';
