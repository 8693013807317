import autodux from 'autodux';
import _ from 'lodash';
import etherscanUrl from '../../components/EtherscanUrl';
import { pendingList } from '../../components/PendingList/PendingList.redux';

const {
  notifierEnqueue,
} = require('../../modules/store/reducers/notifier').actions;

const tokens = autodux({
  slice: 'tokens',
  initial: {
    fetching: false,
    initialized: false,
    infos: [],
  },
  actions: {
    setFetchingTokens: state => ({
      ...state,
      fetching: true,
      initialized: false,
    }),
    setTokensFetched: (state, payload) => ({
      ...state,
      infos: payload,
      fetching: false,
      initializing: true,
    }),
    setTokensInitialized: state => ({
      ...state,
      initializing: false,
      initialized: true,
    }),
  },
});

const tokenFactory = autodux({
  slice: 'tokenFactory',
  initial: {
      fetching: false,
      initialized: false,
  },
  actions: {
      setInitializingTokenFactory: state => ({...state, fetching: true, initialized: false }),
      setInitializedTokenFactory: state => ({...state, fetching: false, initialized: true }),
  }
})

const accessLists = autodux({
    slice: 'accessLists',
    initial: {
        fetching: false,
        initialized: false,
        contracts: [],
    },
    actions: {
        setFetchingAccessLists: state => ({...state, fetching: true, initialized: false }),
        setAccessListsFetched: (state, payload) => ({ ...state, contracts: payload, fetching: false }),
        setAccessListsInitialized: state => ({...state, initialized: true }),
    }
})

const txHistory = autodux({
  slice: 'txHistory',
  initial: {
    fetching: false,
    txs: [],
  },
  actions: {
    setFetchingTxHistory: state => ({ ...state, fetching: true }),
    setTxHistoryFetched: (state, payload) => ({
      ...state,
      txs: payload,
      fetching: false,
    }),
    setHistoryCacheHit: (state) => ({
      ...state,
      fetching: false
    })
  },
});

const drizzle = autodux({
  slice: 'drizzle',
  initial: {
    instance: null,
  },
});

const account = autodux({
  slice: 'connectedAccount',
  initial: '0x0',
});

const getError = error => {
  if (error.code === -32603)
    return "Contract transaction error. If you're getting these errors frequently, please contact the sys admin";
  return (
    error.message ||
    "Transaction error. If you're getting these errors frequently, please contact the sys admin"
  );
};

const contractSetupMiddleware = api => next => action => {
    switch (action.type) {
        case 'SEND_CONTRACT_TX':
            api.dispatch(notifierEnqueue({
                message: `Awaiting transaction signature...`,
                options: { variant: 'info' }
            }))
            next(action)
            break
        case 'TX_BROADCASTED':
            const { networkId } = api.getState().web3
            api.dispatch(notifierEnqueue({
                message: `Transaction sent. You'll be shown a notification when it's mined.`,
                options: {
                    variant: 'success',
                    action: () => etherscanUrl(networkId, action.txHash)
                }
            }))
            next(action)
            break
        case 'TX_ERROR':
            api.dispatch(notifierEnqueue({
                message: getError(action.error),
                options: {
                    variant: 'error'
                }
            }))
            next(action)
            break;
        case 'CONTRACT_INITIALIZED':
            const { contracts, tokens: { infos, initializing }} = api.getState()
            if (!initializing) return next(action)
            // tests if token address is in drizzle contracts; it'll be initialized after middleware execution
            const allContractsInitialized = _.every(
                _.map(infos, 'address'), key =>
                    _.includes(_.keys(contracts), key)
                )
            if (allContractsInitialized) {
                api.dispatch(tokens.actions.setTokensInitialized())
            }
            next(action)
            break
        case 'ACCOUNTS_FETCHED':
            // detect account change
            if (api.getState().connectedAccount !== action.accounts[0]) {
                // reload tokens except when it's the first setup
                if (api.getState().connectedAccount !== '0x0') {
                    api.dispatch(tokens.actions.setFetchingTokens())
                    api.dispatch(pendingList.actions.fetchPendingList())
                }
              // store connected account
              api.dispatch(account.actions.setConnectedAccount(action.accounts[0]))
            }
            next(action)
            break
        default:
            next(action)
    }
}

export { tokenFactory, tokens, accessLists, drizzle, account, txHistory, contractSetupMiddleware }
