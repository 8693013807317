import { put, call, takeEvery, select } from 'redux-saga/effects'
import _ from 'lodash'

import TokenAccessList from '../../../contracts/TokenAccessList.json'
import { accessLists } from '../../../containers/Blockchain/Blockchain.redux'
import { actions } from './AccessList.redux'

const { getContracts } = accessLists.selectors
const { setFetchingEvents, setEvents } = actions

const filterEventParams = abi => _(abi)
    .filter({ type: 'event' })
    .map(({ name, inputs }) =>
        ({ name, params: _.map(inputs, 'name') })
    ).value()

const filterValues = (name, values) => {
    const event = _.find(accessListEvents, { name } )
    return _.pick(values, event.params)
}

const accessListEvents = filterEventParams(TokenAccessList.abi)

const API = {
    fetchEvents: async (contract, blockNumber) => await contract.getPastEvents('allEvents', { fromBlock: blockNumber }),
}

// fetch data from service using sagas
function *fetchAccessListEvents({ payload: { address } }) {
    const lists = yield select(getContracts)
    const accessList = _.find(lists, { address })
    if (!accessList) return yield put(setEvents([]))
    const events = yield call(API.fetchEvents, accessList.web3Contract, accessList.blockNumber)
    const filteredEvents = _(events)
        // filter enable/disable events
        .filter(event => _.includes([ 'WalletEnabled', 'WalletDisabled' ], event.event))
        // filter out irrelevant fields
        .map(({ event: name, returnValues }) => ({
            name,
            ...filterValues(name, returnValues)
        }))
        .value()
    yield put(setEvents(filteredEvents))
}

// app root saga
function *fetchAccessListEventsSaga() {
    yield takeEvery(setFetchingEvents.type, fetchAccessListEvents)
}

export const accessListEventsSagas = [fetchAccessListEventsSaga]