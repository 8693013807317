import styled from 'styled-components';

export const HeaderInnerInfo = styled.header`
  display: flex;
  flex: 6;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
`;

HeaderInnerInfo.displayName = 'HeaderInnerInfo';
