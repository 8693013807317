const reducer = 'locale'

/** SELECTORS */
const getCurrent = state => state[reducer].current

export const selectors = {
  getCurrent
}

/** TYPES */
const CHANGE = `${reducer}/CHANGE`

export const types = {
  CHANGE,
}

/** ACTIONS */
const localeChange = locale => ({
  type: types.CHANGE,
  locale
})

export const actions = {
  localeChange,
}

/** REDUCERS */
const defaultState = {
  current: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE:
      return {
        ...state,
        current: action.locale
      }

    default:
      return state
  }
}