import { linearGradient, darken } from 'polished';

export const COLORS = Object.freeze({
  BLACK: '#000',
  WHITE: '#FFF',
  GRAY: '#F6F9FB',
  DARK_GRAY: '#BDC5CE',
  DARKEST_GRAY: '#313232',
  PRIMARY: '#8C6EF4',
  SECONDARY: '#4D30B6',
  LIGHT_GREEN: '#3ecebd',
  GREEN: '#20b3a2',
  RED: '#de3535',
  YELLOW: '#fddc6c',
});

export const GRADIENTS = Object.freeze({
  PRIMARY_TO_SECONDARY: linearGradient({
    colorStops: [darken(0.05, COLORS.PRIMARY), COLORS.SECONDARY],
    fallback: COLORS.PRIMARY,
    toDirection: 'to bottom',
  }),
  PRIMARY_TO_SECONDARY_RIGHT: linearGradient({
    colorStops: [COLORS.PRIMARY, COLORS.SECONDARY],
    fallback: COLORS.PRIMARY,
    toDirection: 'to right',
  }),
  GREEN: linearGradient({
    colorStops: [COLORS.GREEN, COLORS.LIGHT_GREEN],
    fallback: COLORS.GREEN,
    toDirection: 'to right',
  }),
});
