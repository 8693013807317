import { keyframes } from 'styled-components';

export const ROTATE = extra => keyframes`
  0% {
    transform: ${extra} rotate(0);
  }
  100% {
    transform: ${extra} rotate(359deg);
  }
`;

export const ROTATE_INVERSE = extra => keyframes`
  0% {
    transform: ${extra} rotate(0);
  }
  100% {
    transform: ${extra} rotate(-359deg);
  }
`;
