import styled from 'styled-components';
import { COLORS } from '../../../constants/colors';

export const ProductName = styled.h1`
  font-size: 24px;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 5px;
  color: ${COLORS.BLACK};
`;

ProductName.displayName = 'ProductName';
