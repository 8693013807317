import styled from 'styled-components';
import { transparentize } from 'polished';
import { COLORS } from '../../../../constants/colors';

export const Description = styled.p`
  font-size: 14px;
  color: ${transparentize(0.3, COLORS.DARKEST_GRAY)};
`;

Description.displayName = 'Description';
