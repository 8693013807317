import styled from 'styled-components';
import { Extra } from './Extra';

export const ContentTemplate = styled.div`
  width: 100%;
  padding: 40px;
  overflow: auto;
  height: 100%;
`;

ContentTemplate.displayName = 'ContentTemplate';
ContentTemplate.Extra = Extra;
