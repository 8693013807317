import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';

window.version = version;

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
