import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ center }) => center && CENTER}
  ${({ reverse }) => reverse && REVERSE}
  ${({ spaced }) => spaced && SPACED}
`;

const CENTER = css`
  justify-content: center;
  align-items: center;
`;

const REVERSE = css`
  flex-direction: row-reverse;
`;

const SPACED = css`
  && {
    margin-bottom: 70px;
  }
`;

Row.displayName = 'Row';
