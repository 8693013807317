import { firebaseReducer } from 'react-redux-firebase';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  drizzleSagas,
  drizzleReducers,
  generateContractsInitialState,
} from '@drizzle/store';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import drizzleOptions from './drizzleOptions';
import drizzleMiddleware from '../drizzle/drizzle-middleware';

// reducers
import {
  tokenFactory,
  tokens,
  accessLists,
  drizzle,
  account,
  txHistory,
} from '../../containers/Blockchain/Blockchain.redux';
import eventList from '../../components/EventList/EventList.redux';
import { pendingList } from '../../components/PendingList/PendingList.redux';
import { reducer as walletReducer } from '../../routes/pages/WalletState/WalletState.redux';
import { reducer as accessListEventsReducer } from '../../routes/pages/AccessList/AccessList.redux';
import notifier from './reducers/notifier';
import locale from './reducers/locale';

// sagas
import { blockchainSagas } from '../../containers/Blockchain/Blockchain.sagas';
import { tokenControlSagas } from '../../components/EventList/EventList.sagas';
import { pendingListSagas } from '../../components/PendingList/PendingList.sagas';
import { walletSagas } from '../../routes/pages/WalletState/WalletState.sagas';
import { accessListEventsSagas } from '../../routes/pages/AccessList/AccessList.sagas';
import contractsSaga from '../drizzle/contractsSaga';

// middlewares
import { tokenFormMiddleware } from '../../routes/pages/TokenForm/TokenForm.redux';
import { contractSetupMiddleware } from '../../containers/Blockchain/Blockchain.redux';
import { lifecycleFormMiddleware } from '../../reducers/Lifecycle';
import { mintFormMiddleware } from '../../components/MintForm/MintForm.redux';
import { burnFormMiddleware } from '../../components/BurnForm/BurnForm.redux';
import { rolesFormMiddleware } from '../../components/RolesForm/RolesForm.redux';
import { capControlFormMiddleware } from '../../components/CapControlForm/CapControlForm/CapControlForm.redux';
import { capAmountFormMiddleware } from '../../components/CapControlForm/CapAmountForm/CapAmountForm.redux';
import { forcedTransferFormMiddleware } from '../../components/ForcedTransferForm/ForcedTransferForm.redux';
import { pendingListMiddleware } from '../../components/PendingList/PendingList.redux';
import { accessListFormMiddleware } from '../../routes/pages/AccessListForm/AccessListForm.redux';
import { tokenAccessListFormMiddleware } from '../../components/TokenAccessListForm/TokenAccessListForm.redux';
import { accessListMiddleware } from '../../routes/pages/AccessList/AccessList.redux';

const reducers = {
  connectedAccount: account.reducer,
  tokenFactory: tokenFactory.reducer,
  tokens: tokens.reducer,
  accessLists: accessLists.reducer,
  accessListEvents: accessListEventsReducer,
  drizzle: drizzle.reducer,
  walletTxs: walletReducer,
  eventList: eventList.reducer,
  txHistory: txHistory.reducer,
  pendingList: pendingList.reducer,
  notifier,
  locale,
  firebase: firebaseReducer,
};

const middlewares = [
  contractSetupMiddleware,
  tokenFormMiddleware,
  lifecycleFormMiddleware,
  mintFormMiddleware,
  capControlFormMiddleware,
  capAmountFormMiddleware,
  forcedTransferFormMiddleware,
  burnFormMiddleware,
  rolesFormMiddleware,
  pendingListMiddleware,
  accessListFormMiddleware,
  tokenAccessListFormMiddleware,
  accessListMiddleware,
];

// modified drizzle saga

drizzleSagas[3] = contractsSaga;

const sagas = [
  ...drizzleSagas,
  ...blockchainSagas,
  ...tokenControlSagas,
  ...walletSagas,
  ...accessListEventsSagas,
  ...pendingListSagas,
];

let initialContractsState = {
  contracts: generateContractsInitialState(drizzleOptions),
};

const sagaMiddleware = createSagaMiddleware();
const allMiddlewares = [...middlewares, sagaMiddleware, drizzleMiddleware];
const allReducers = { ...drizzleReducers, ...reducers };

const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose

const store = createStore(
  combineReducers(allReducers),
  initialContractsState,
  composeEnhancers(applyMiddleware(...allMiddlewares))
);

const composeSagas = sagas =>
  function*() {
    yield all(sagas.map(fork));
  };

sagaMiddleware.run(composeSagas(sagas));

export default () => store;
