import React from 'react';
import { Template } from './Template';
import { OneLogo } from '../OneLogo';
import { Grid } from '../../Grid';
import Button from '../../Button';

export const Nav = () => (
  <Template>
    <Grid flex center>
      <OneLogo />
      <Button as="a" href="/login">
        Sign in
      </Button>
    </Grid>
  </Template>
);
