const { notifierEnqueue } = require('../../modules/store/reducers/notifier').actions

/**
 * PUSH_TO_TXSTACK 
 * SEND_CONTRACT_TX { args, contract, fnName }
 * TX_BROADCASTED { txHash }
 * CONTRACT_SYNC_IND
 * BLOCK_RECEIVED
 * EVENT_FIRED
 * TX_SUCCESSFUL { txHash, receipt }
 */

export const forcedTransferFormMiddleware = api => next => action => {
    switch (action.type) {
        case 'EVENT_FIRED':
            const { event } = action.event
            if (event === 'ForcedTransfer') {
                api.dispatch(notifierEnqueue({
                    message: 'Tokens successfully transferred',
                    options: { variant: 'success' },
                    txHash: action.event.transactionHash
                }))
            }
            next(action)
            break
        default:
            next(action)
        }
}