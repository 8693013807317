import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../../components/Loader';

const Lazy = ({ component: Component, ...props }) => (
  <React.Suspense fallback={<Loader />}>
    <Component {...props} />
  </React.Suspense>
);

Lazy.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Lazy;
