import React from "react";
import { drizzleConnect } from "@drizzle/react-plugin"
import PropTypes from "prop-types"

import View from './Blockchain.view'

import { tokenFactory, tokens, accessLists, drizzle } from './Blockchain.redux'
const { actions : { setInstance } } = drizzle
const { actions: { setFetchingTokens } } = tokens
const { actions: { setInitializingTokenFactory } } = tokenFactory
const { actions: { setFetchingAccessLists } } = accessLists

const mapDispatchToProps = dispatch => {
    return {
        setInstance: drizzle => dispatch(setInstance(drizzle)),
        initializeTokenFactory: () => dispatch(setInitializingTokenFactory()),
        fetchTokens: () => dispatch(setFetchingTokens()),
        fetchAccessLists: () => dispatch(setFetchingAccessLists()),
    }
}

class Blockchain extends React.Component {
    constructor(props, context) {
        super(props)
        // used by sagas to read events
        props.setInstance(context.drizzle)
        // initialize token factory
        props.initializeTokenFactory()
        // gets all tokens created from TokenFactory events
        props.fetchTokens()
        props.fetchAccessLists()
    }
    render() {
        return <View>{this.props.children}</View>
    }
}

Blockchain.contextTypes = {
    drizzle: PropTypes.object,
}

export default drizzleConnect(Blockchain, null, mapDispatchToProps)
