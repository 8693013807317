import React from 'react';
import { Link } from '@material-ui/core';
import { Button } from '../Button';
import { trimWithEllipsis } from '../../utils/trimWithEllipsis';
import { ETHERSCAN_URLS } from '../../constants/etherscanUrls';

export default (networkId, hash, linkOnly) => {
  return linkOnly ? (
    <Link
      href={`${ETHERSCAN_URLS[networkId]}${hash}`}
      target="_blank"
      rel="noopener"
      color="inherit"
    >
      {trimWithEllipsis(hash)}
    </Link>
  ) : (
    <Button
      as="a"
      target="_blank"
      small
      white
      href={`${ETHERSCAN_URLS[networkId]}${hash}`}
    >
      View
    </Button>
  );
};
