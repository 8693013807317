import styled from 'styled-components';

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 55px 0 0 55px;
  height: 100%;
`;

MainContent.displayName = 'MainContent';
