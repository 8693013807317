const { notifierEnqueue } = require('../../modules/store/reducers/notifier').actions
const { ROLES_EVENTS } = require('../../utils/events')

/**
 * PUSH_TO_TXSTACK 
 * SEND_CONTRACT_TX { args, contract, fnName }
 * TX_BROADCASTED { txHash }
 * CONTRACT_SYNC_IND
 * BLOCK_RECEIVED
 * EVENT_FIRED
 * TX_SUCCESSFUL { txHash, receipt }
 */

export const rolesFormMiddleware = api => next => action => {
    switch (action.type) {
        case 'EVENT_FIRED':
            const { event, returnValues } = action.event
            if (ROLES_EVENTS.includes(event)) {
                const isEnable = event.endsWith('Added')
                const [ role ] = isEnable ? event.split('Added') : event.split('Removed')
                const { account } = returnValues
                api.dispatch(notifierEnqueue({
                    message: `${role} role sucessfuly ${isEnable ? 'granted to' : 'revoked from'} wallet '${account}'`,
                    options: { variant: 'success' },
                    txHash: action.event.transactionHash
                }))
            }
            next(action)
            break
        default:
            next(action)
        }
}