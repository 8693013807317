import styled, { css } from 'styled-components';
import { Row } from './Row';
import { Col } from './Col';
import { MEDIA_MIN } from '../../utils/mediaQuery';
import { MAX_WIDTHS } from '../../constants/screen';

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  
  ${MEDIA_MIN.SM`max-width: ${MAX_WIDTHS.SM}px`}
  ${MEDIA_MIN.MD`max-width: ${MAX_WIDTHS.MD}px`}
  ${MEDIA_MIN.LG`max-width: ${MAX_WIDTHS.LG}px`}
  ${MEDIA_MIN.XL`max-width: ${MAX_WIDTHS.XL}px`}

  ${({ flex }) => flex && FLEX}
  ${({ center }) => center && CENTER}
  ${({ fluid }) => fluid && FLUID}
  ${({ noGutter }) => noGutter && NO_GUTTER}
`;

const FLEX = css`
  display: flex;
  flex-wrap: wrap;
`;

const FLUID = css`
  max-width: none !important;
`;

const CENTER = css`
  align-items: center;
`;

const NO_GUTTER = css`
  padding-right: 0px;
  padding-left: 0px;
`;

Grid.displayName = 'Grid';
Grid.Row = Row;
Grid.Col = Col;
