import _ from 'lodash'
import autodux from 'autodux'

export const { actions, selectors, reducer } = autodux({
    slice: 'accessListEvents',
    initial: {
        fetching: false,
        params: {},
        events: [],
    },
    actions: {
        setFetchingEvents: (state, payload) => ({...state, params: payload, fetching: true }),
        setEvents: (state, payload) => ({ ...state, events: payload, fetching: false }),
    }
})

const { notifierEnqueue } = require('../../../modules/store/reducers/notifier').actions

/**
 * PUSH_TO_TXSTACK 
 * SEND_CONTRACT_TX { args, contract, fnName }
 * TX_BROADCASTED { txHash }
 * CONTRACT_SYNC_IND
 * BLOCK_RECEIVED
 * EVENT_FIRED
 * TX_SUCCESSFUL { txHash, receipt }
 */

export const accessListMiddleware = api => next => action => {
    switch (action.type) {
        case 'EVENT_FIRED':
            const { event } = action.event
            if (_.includes([ 'WalletEnabled', 'WalletDisabled' ], event)) {
                api.dispatch(notifierEnqueue({
                    message: `Wallet successfully ${event === 'WalletEnabled' ? 'enabled' : 'disabled'}.`,
                    options: { variant: 'success' },
                    txHash: action.event.transactionHash
                }))
            }
            next(action)
            break
        default:
            next(action)
        }
}