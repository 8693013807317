import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

import NotifierView from './Notifier.view'
const { notifierDismiss } = require('../../modules/store/reducers/notifier').actions
const { getNotifications } = require('../../modules/store/reducers/notifier').selectors

const mapStateToProps = store => ({
  notifications: getNotifications(store),
});

const mapDispatchToProps = dispatch => bindActionCreators({ notifierDismiss }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withSnackbar(NotifierView))