import styled from 'styled-components';
import { transparentize } from 'polished';
import { COLORS } from '../../../constants/colors';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 55px;

  border-left: 1px solid ${COLORS.GRAY};
  height: 55px;
  width: calc(100% - 55px);
  background-color: ${COLORS.WHITE};
  padding: 0 20px;
  box-shadow: 3px 0 3px ${transparentize(0.8, COLORS.BLACK)};
`;

HeaderWrapper.displayName = 'HeaderWrapper';
