import autodux from 'autodux'

export default autodux({
    slice: 'eventList',
    initial: {
        fetching: false,
        params: {},
        events: [],
    },
    actions: {
        setFetchingEvents: (state, payload) => ({...state, params: payload, fetching: true }),
        setEvents: (state, payload) => ({ ...state, events: payload, fetching: false })
    }
})