import styled, { css } from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 4px;
    left: 0;
    top: calc(100% + 5px);
    background-color: ${COLORS.SECONDARY};
  }

  ${({ center }) => center && CENTER}
`;

const CENTER = css`
  justify-content: center;

  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
`;

Title.displayName = 'Title';
