import styled from 'styled-components';
import { COLORS, GRADIENTS } from '../../../constants/colors';

export const Template = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  color: ${COLORS.WHITE};
  padding: 0 15px;
  margin-right: 18px;

  ${GRADIENTS.GREEN}
`;

Template.displayName = 'Template';
