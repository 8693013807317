import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import { ContentTemplate } from './ContentTemplate';
import { Title } from '../Title';

const ContentWrapper = pure(({ children, title, titleExtra }) => (
  <ContentTemplate>
    <Title>
      {title}
      {titleExtra && (
        <ContentTemplate.Extra>{titleExtra}</ContentTemplate.Extra>
      )}
    </Title>
    {children}
  </ContentTemplate>
));

ContentWrapper.propTypes = {
  children: PropTypes.node,
};

export default ContentWrapper;
