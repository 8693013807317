import styled, { css } from 'styled-components';
import { COLORS } from '../../../constants/colors';
import { MEDIA_MAX } from '../../../utils/mediaQuery';

const LARGE = css`
  font-size: 40px;
`;

const CENTER = css`
  margin: 0 auto 40px;
  &:after {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
  position: relative;
  text-transform: uppercase;
  max-width: 390px;

  &:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 4px;
    left: 0;
    top: calc(100% + 5px);
    background-color: ${COLORS.PRIMARY};
  }

  ${MEDIA_MAX.MD`
    font-size: 22px;
    ${CENTER}
  `}

  ${({ large }) => large && LARGE}
  ${({ center }) => center && CENTER}
`;

Title.displayName = 'Title';
