import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { takeEvery, take, select, put } from 'redux-saga/effects'

import { pendingList }  from './PendingList.redux'

const {
    actions: {
        savePendingTx,
        setPendingList,
        refreshPendingTxs,
        setChannel,
        channelSet,
    },
} = pendingList

const createEventChannel = (networkId, connectedAccount) =>
    eventChannel(
        emit => {
            return firebase.firestore()
                .collection('pendingTxs')
                .where('type', '==', 'TX_BROADCASTED')
                .where('networkId', '==', networkId)
                .where('customerId', '==', connectedAccount)
                .orderBy('created', 'desc')
                .onSnapshot(snapshot => {
                    emit(snapshot.docs.map(doc => doc.data()))
                })
        }
    )

function *savePending({ payload }) {
    const { web3: { networkId }, connectedAccount } = yield select()
    yield firebase.functions().httpsCallable('saveTx')({ ...payload, networkId, customerId: connectedAccount })
}

function *setupChannel() {
    const { web3: { networkId }, connectedAccount, pendingList } = yield select()
    if (pendingList.settingChannel || pendingList.channelSet) return
    yield put(setChannel())
    const pendingListChannel = createEventChannel(networkId, connectedAccount)
    yield put(channelSet())
    while (true) {
        const list = yield take(pendingListChannel)
        yield put(setPendingList(list))
    }
}

function *refresh() {
    const state = yield select()
    const { web3: { networkId }, connectedAccount, drizzle: { instance: { web3 }} } = state
    const { data: list } = yield firebase.functions().httpsCallable('getPendingList')({ networkId, customerId: connectedAccount })
    const txs = yield Promise.all(
        _.map(list, async tx => {
            return {
                ...tx,
                receipt: await web3.eth.getTransactionReceipt(tx.txHash)
            }
        })
    )
    const txsToRefresh = _.filter(txs, tx => !_.isNil(tx.receipt))
    yield Promise.all(
        _.map(txsToRefresh, async tx => {
            await firebase.functions().httpsCallable('saveTx')({ ...tx, type: tx.receipt.status ? 'TX_SUCCESSFUL' : 'TX_ERROR' })
        })
    )
    try {
        yield setupChannel()
    } catch (error) {
        // TODO: decide what to do
    }
}

function *savePendingTxSaga() {
    yield takeEvery(savePendingTx.type, savePending)
}

function *refreshPendingTxSaga() {
    yield takeEvery(refreshPendingTxs.type, refresh)
}

export const pendingListSagas = [savePendingTxSaga, refreshPendingTxSaga]
