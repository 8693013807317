import { put, call, takeEvery, select } from 'redux-saga/effects'
import _ from 'lodash'

import ControlledToken from '../../contracts/ControlledToken.json'
import tokenPastEvents from './EventList.redux'
import { tokens } from '../../containers/Blockchain/Blockchain.redux'
import { filterEventParams, filterValues, inferEvents } from '../../utils/events'

const controlledTokenEvents = filterEventParams(ControlledToken)

const {
    actions: {
        setFetchingEvents,
        setEvents,
    },
    selectors: {
        getParams,
    }
} = tokenPastEvents

const { selectors: { getInfos } } = tokens

const API = {
    fetchTokenEvents: async (contract, blockNumber) => await contract.getPastEvents('allEvents', { fromBlock: blockNumber }),
}

// fetch data from service using sagas
function *fetchTokenEvents() {
    const { tokenAddress } = yield select(getParams)
    const { web3Contract, blockNumber } = _.find(yield select(getInfos), { address: tokenAddress })
    const events = yield call(API.fetchTokenEvents, web3Contract, blockNumber)
    const formattedEvents = _(events)
        .orderBy('blockNumber', 'desc')
        .map(({ event: name, returnValues }) => ({ // filter out irrelevant fields
            name,
            values: filterValues(controlledTokenEvents)(name)(returnValues)
        }))
        .map(inferEvents)
        .value()
    yield put(setEvents(formattedEvents))
}

// app root saga
function *fetchTokenEventsSaga() {
    yield takeEvery(setFetchingEvents.type, fetchTokenEvents)
}

export const tokenControlSagas = [fetchTokenEventsSaga]