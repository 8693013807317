import _ from 'lodash'
import { ZERO_ADDRESS } from './constants'

export const USER_EVENTS = [
    'Transfer',
    'Approval'
]

export const ROLES_EVENTS = [
    'CreatorAdded',
    'CreatorRemoved',
    'MinterAdded',
    'MinterRemoved',
    'OperatorAdded',
    'OperatorRemoved',
    'BurnerAdded',
    'BurnerRemoved',
    'PauserAdded',
    'PauserRemoved',
    'OwnershipTransferred'
]

export const ADMIN_EVENTS = [
    'ForcedTransfer',
    'Transfer',
    'ForcedBurn',
    'Paused',
    'Unpaused',
    'AccessListSet',
    'AccessListUnset',
    'CapEnabled',
    'CapDisabled',
    'CapSet',
    ...ROLES_EVENTS
]

export const filterEventParams = ({ abi }) => _(abi)
    .filter({ type: 'event' })
    .map(({ name, inputs }) =>
        ({ name, params: _.map(inputs, 'name') })
    ).value()

export const mergeEvents = (...events) =>
    _(_.concat([], ...events))
        .uniqWith(_.isEqual)
        .value()

export const filterValues = spec => name => values => {
    const event = _.find(spec, { name } )
    return _.pick(values, event.params)
}

export const isAdminEvent = ({ name }) => ADMIN_EVENTS.includes(name)

export const isRoleEvent = ({ name }) => ROLES_EVENTS.includes(name)

// features like mint and burn don't have events; they must be inferred
export const inferEvents = ({ name, values }) => {
    let inferredName
    if (name === 'Transfer') {
        if (values.from === ZERO_ADDRESS) inferredName = 'Mint'
        if (values.to === ZERO_ADDRESS) inferredName = 'Burn'
    }
    return {
        name: inferredName || name,
        values
    }
}
