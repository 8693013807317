import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { COLORS } from '../constants/colors';

export const GlobalStyle = createGlobalStyle`
  ${reset}
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

  html, body {
    height: 100%;
    width: 100vw;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: normal;
    color: ${COLORS.BLACK};
    background-color: ${COLORS.GRAY};
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  button {
    cursor: pointer;
    border: 0;
  }

  a {
    text-decoration: none;
  }
`;

export const snackbarStyles = {
  variantSuccess: {
    backgroundColor: COLORS.LIGHT_GREEN,
  },
  variantError: {
    backgroundColor: COLORS.RED,
  },
  variantWarning: {
    backgroundColor: COLORS.YELLOW,
  },
  variantInfo: {
    backgroundColor: COLORS.SECONDARY,
  },
};
