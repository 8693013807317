import React from 'react'
import _ from 'lodash'

class Notifier extends React.Component {
  displayed = []

  storeDisplayed = notification => {
    this.displayed = [...this.displayed, notification]
    // this.displayed = [...this.displayed, id]
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props
    let notExists = false
    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length
    }
    return notExists
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props
    notifications.forEach(notification => {
      // Do nothing if snackbar is already displayed
      if (
        _.find(this.displayed, { key: notification.key }) || ( // if the key was displayed or
          notification.txHash && // there is a txHash
          _.find(this.displayed, { txHash: notification.txHash }) // the hash was displayed
          )
      ) return
      // Display snackbar using notistack
      this.props.enqueueSnackbar(notification.message, notification.options)
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification)
      // Dispatch action to remove snackbar from redux store
      this.props.notifierDismiss(notification.key)
    })
  }

  render() {
    return null
  }
}

export default Notifier