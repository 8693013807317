import React from 'react';
import { Nav } from './Nav';
import { Header } from './Header';
import { Details } from './Details';
import { CallToAction } from './CallToAction';
import { GenericBlock } from './GenericBlock';
import { Footer } from './Footer';
import { COLORS } from '../../constants/colors';
import admin from '../../assets/img/admin.png';
import chart from '../../assets/img/chart.png';
import background from '../../assets/img/apple-1841553_1920.jpg';

export const LandingPage = () => (
  <>
    <Nav />
    <Header />
    <Details />
    <GenericBlock
      title="All you need in one single place."
      description="With Oken, you can manage the whole digital asset lifecycle, from issuance to transactions. All with an easy-to-use interface and with end-to-end security."
      alignRight
      size={6}
      image={{ src: admin, alt: 'Admin' }}
      center
      roundSeparator
    />
    <GenericBlock
      title="Running on top of cutting-edge technology."
      description="Oken supports both Ethereum and Rsk.co Blockchain Smart Contracts. All transactions are secured and protected by end-to-end cryptography."
      size={6}
      image={{ src: chart, alt: 'Chart' }}
      backgroundColor={COLORS.WHITE}
      center
      reverse
    />
    <GenericBlock
      title="Flexible plans."
      description="Oken is available in a SaaS and On-Premises offer. You can have a monthly subscription, an yearly plan or a pay-as-you-go contract. Please contact us for more information."
      size={6}
      color={COLORS.WHITE}
      backgroundImage={background}
      backgroundColor={COLORS.DARKEST_GRAY}
      alignRight
    />
    <CallToAction />
    <Footer />
  </>
);
