import styled from 'styled-components';
import { transparentize } from 'polished';
import { COLORS } from '../../constants/colors';
import { ROTATE, ROTATE_INVERSE } from '../../constants/animations';

export const Loader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: ${transparentize(0.2, COLORS.WHITE)};

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;

    background-color: transparent;
    border: 4px solid ${COLORS.SECONDARY};
    border-left-color: transparent;
    border-radius: 50%;
    animation: ${ROTATE('translate(-50%, -50%)')} 1s infinite linear;
  }

  &:after {
    width: 115px;
    height: 115px;
    border: 3px solid ${COLORS.PRIMARY};
    border-left-color: transparent;
    animation: ${ROTATE_INVERSE('translate(-50%, -50%)')} 1s infinite linear;
  }
`;

Loader.displayName = 'Loader';
