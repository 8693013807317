import React from 'react'
import { drizzleConnect } from '@drizzle/react-plugin'

import View from './PendingList.view'

const PendingList = props => {
    const { pendingList: { list, fetching }} = props
    return <View list={list} isLoading={fetching} />
}

export default drizzleConnect(PendingList, ({ pendingList }) => ({ pendingList }))