import autodux from 'autodux'

export const { actions, selectors, reducer } = autodux({
    slice: 'walletTxs',
    initial: {
        fetching: false,
        params: {},
        events: [],
    },
    actions: {
        setFetchingEvents: (state, payload) => ({...state, params: payload, fetching: true }),
        setEvents: (state, payload) => ({ ...state, events: payload, fetching: false }),
        clearEvents: state => ({ ...state, events: [], })
    }
}) 