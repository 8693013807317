import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import config from './config.json';

export default () => {
  firebase.initializeApp(config);
  firebase.firestore();
  firebase.functions();
  firebase.auth();
  // TODO: uncomment
  // firebase.functions().useFunctionsEmulator('http://localhost:5000');

  window.firebase = firebase;
};
