import React from 'react';
import { LandingTemplate } from '../LandingTemplate';
import { Grid } from '../../Grid';
import { Button } from '../../Button';
import { COLORS } from '../../../constants/colors';
import pattern from '../../../assets/img/pattern.png';

export const CallToAction = () => (
  <LandingTemplate
    color={COLORS.WHITE}
    backgroundColor={COLORS.SECONDARY}
    backgroundImage={pattern}
    center
    style={{ backgroundAttachment: 'unset' }}
  >
    <Grid>
      <LandingTemplate.Title center>
        Start using right now!
      </LandingTemplate.Title>
      <LandingTemplate.Description spaced center>
        Oken is fully operational and available. If you already have an user account, you can easily sign-in. If don't have your account yet, please contact us for more information.
      </LandingTemplate.Description>
      <Button large as="a" href="mailto:business@onepercent.io" white>
        Let's talk
      </Button>
    </Grid>
  </LandingTemplate>
);
