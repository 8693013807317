import React from 'react'
import { Link } from 'react-router-dom'
import {
    Badge,
    Tooltip,
} from '@material-ui/core'
import {
    Notifications,
} from '@material-ui/icons'

import './PendingList.style.css'

const PendingList = props => {
    const pending = props.list.length
    return (
        <span className="pending-list">
        <Tooltip title={`Pending transactions: ${pending}`} placement="bottom">
            <Badge badgeContent={pending} color="secondary">
                <Link to="/pending-list">
                    <Notifications />
                </Link>
            </Badge>
        </Tooltip>
        </span>
    )
}

export default PendingList