import styled, { css } from 'styled-components';
import { transitions, darken, hideText } from 'polished';
import { Container } from './Container';
import { Link } from './Link';
import { COLORS } from '../../constants/colors';
import { ROTATE } from '../../constants/animations';

export const Button = styled.button.attrs({ type: 'button' })`
  && {
    font-size: 14px;
    padding: 8px 22px;
    border-radius: 8px;
    border: 2px solid ${COLORS.PRIMARY};
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
    text-decoration: none;
    display: inline-block;
    margin-right: 10px;
    text-shadow: none;
    cursor: pointer;
    ${transitions(['background-color', 'border-color'], '.3s')}

    &:last-of-type {
      margin-right: 0;
    }

    ${({ loading }) =>
      !loading &&
      css`
      &:hover {
        background-color: ${COLORS.SECONDARY};
        border-color: ${COLORS.SECONDARY};
      }
      ${({ secondary }) => secondary && SECONDARY}
      ${({ cancel }) => cancel && CANCEL}
      ${({ green }) => green && GREEN}
      ${({ white }) => white && WHITE}
      ${({ disabled }) => disabled && DISABLED}
      ${({ small }) => small && SMALL}
      ${({ large }) => large && LARGE}
      ${({ icon }) => icon && ICON}
    `}

    ${({ loading }) => loading && LOADING}
  }
`;

const SECONDARY = css`
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.PRIMARY};
  color: ${COLORS.SECONDARY};

  &:hover {
    background-color: ${COLORS.GRAY};
  }
`;

const CANCEL = css`
  background-color: ${COLORS.RED};
  border-color: ${COLORS.RED};
  color: ${COLORS.WHITE};

  &:hover {
    background-color: ${darken(0.1, COLORS.RED)};
    border-color: ${darken(0.1, COLORS.RED)};
  }
`;

const GREEN = css`
  background-color: ${COLORS.GREEN};
  border-color: ${COLORS.GREEN};
  color: ${COLORS.WHITE};

  &:hover {
    background-color: ${darken(0.1, COLORS.GREEN)};
    border-color: ${darken(0.1, COLORS.GREEN)};
  }
`;

const DISABLED = css`
  cursor: not-allowed;
  background-color: ${COLORS.DARK_GRAY};
  border-color: ${COLORS.DARK_GRAY};
  color: ${COLORS.WHITE};
  &:hover {
    background-color: ${COLORS.DARK_GRAY};
    border-color: ${COLORS.DARK_GRAY};
    color: ${COLORS.WHITE};
  }
`;

const SMALL = css`
  font-size: 12px;
  padding: 2px 12px;
`;

const LARGE = css`
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
`;

const ICON = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;

  svg {
    font-size: 15px;
  }
`;

const WHITE = css`
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.WHITE};
  color: ${COLORS.SECONDARY};
  &:hover {
    background-color: ${COLORS.GRAY};
    border-color: ${COLORS.GRAY};
    color: ${COLORS.SECONDARY};
  }
`;

const LOADING = css`
  ${hideText()};
  font-size: 0;
  background-color: transparent;
  border: 3px solid ${COLORS.GREEN};
  border-right-color: ${COLORS.DARK_GRAY};
  border-radius: 50%;
  cursor: not-allowed;
  width: 35px;
  height: 35px;
  padding: 0;

  /* &:hover {
    background-color: transparent;
    border-color: ${COLORS.GREEN};
    border-right-color: ${COLORS.DARK_GRAY};
  } */

  animation: ${ROTATE()} 0.6s infinite linear;
`;

Button.displayName = 'Button';
Button.Container = Container;
Button.Link = Link;

// TODO: Remove
export default Button;
