import { put, call, takeEvery, select } from 'redux-saga/effects'
import _ from 'lodash'

import { actions, selectors } from './WalletState.redux'
import ControlledToken from '../../../contracts/ControlledToken.json'
import {
    filterEventParams,
    filterValues,
} from '../../../utils/events'

const API = {
    fetchWalletEvents: async contract => await contract.getPastEvents('allEvents', { fromBlock: contract.blockNumber }),
}

// fetch data from service using sagas
function *fetchWalletEvents() {
    // get query params
    const { wallet, web3Contract } = yield select(selectors.getParams)
    // get all events
    const events = yield call(API.fetchWalletEvents, web3Contract)
    // get specs for all possible events
    const eventsSpec = filterEventParams(ControlledToken)
    // for each event, find its specs and iterate over properties to check if any is the wallet
    const filteredEvents = _(events)
        .filter(event => {
            return _.find(event.returnValues, val => val.toLowerCase() === wallet.toLowerCase())
        })
        .map(({ event: name, returnValues, blockNumber }) => ({ // filter out irrelevant fields
            name,
            blockNumber,
            values: filterValues(eventsSpec)(name)(returnValues)
        }))
        .value()

    yield put(actions.setEvents(filteredEvents))
}

// app root saga
function *fetchWalletEventsSaga() {
    yield takeEvery(actions.setFetchingEvents.type, fetchWalletEvents)
}

export const walletSagas = [fetchWalletEventsSaga]